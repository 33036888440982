<template>
	<div class="home">
		<a-breadcrumb>
			<a-breadcrumb-item href="">
				<a-icon type="home" />
			</a-breadcrumb-item>
			<a-breadcrumb-item href="">
				<a-icon type="calculator" />
				<span>财务</span>
			</a-breadcrumb-item>
			<a-breadcrumb-item> 账单管理 </a-breadcrumb-item>
		</a-breadcrumb>

		<div class="search-list-box">
			<a-form class="ant-advanced-search-form">
				<a-row :gutter="24">
					<a-col :span="4">
						<a-form-item :label="`所属银行：`">
							<a-tree-select placeholder="请选择银行" v-model="searchData.bank_id" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
							 allow-clear :tree-data="bankTreeData">
								<span v-if="key == '0'" slot="title" slot-scope="{ key, value }">
									请选择银行类型
								</span>
							</a-tree-select>
						</a-form-item>
					</a-col>

					<a-col :span="4">
						<a-form-item :label="`网点名称：`">
							<a-select v-model="searchData.node_id" placeholder="请选择网点名称">
								<a-select-option v-for="(item,index) in outletsList" :key="index" :value="item.node_id"> {{item.name}} </a-select-option>
							</a-select>
						</a-form-item>
					</a-col>
					<a-col :span="6">
						<a-form-item :label="`交易时间：`">
							<a-range-picker  :disabledDate="disabledDate" :ranges="{ '今天': [moment(), moment()] }" :key="pickerKey" :placeholder="['交易开始时间', '交易结束时间']" @change="handleChangeDate" style="width: 120px" format="YYYY-MM-DD" />
						</a-form-item>
					</a-col>
					<a-col :span="4">
						<a-form-item :label="`交易结果：`">
							<a-select placeholder="请选择交易结果" v-model="searchData.status">
								<a-select-option :value="1">
									交易成功
								</a-select-option>
								<a-select-option :value="2">
									交易失败
								</a-select-option>
							</a-select>
						</a-form-item>
					</a-col>
					<a-col :span="4">
						<a-button type="primary" @click="getList"> 查询 </a-button>
						<a-button :style="{ marginLeft: '8px' }" @click="handleReset">
							重置
						</a-button>
					</a-col>
				</a-row>
			</a-form>
			<div class="search-result-list">
				<div class="table-header-box">
					<div class="left">
						<h3 class="title">账单列表</h3>
					</div>
					<div class="right">
					</div>
				</div>
				<a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columns" @change="handleTableChange"
				 :pagination="{total: total_page * 10}" :data-source="list" :scroll="{ x: 1200 }" rowKey="tran_id" size="small">
					<span slot="date" slot-scope="date, info">
						{{info.province}} {{info.city}}
					</span>
				</a-table>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import http from '../http'

	const columns = [{
			title: "序号",
			dataIndex: "id",
			width:60
		},{
			title: "设备编号",
			dataIndex: "number",
			width:100
		},
		{
			title: "所属区域",
			dataIndex: "area",
			width:100
		},
		{
			title: "上次加币时间",
			dataIndex: "start_time",
			width:100
		},
		{
			title: "清机时间",
			dataIndex: "end_time",
			width:100
		},
		{
			title: "上次加币金额(元)",
			dataIndex: "add_amount",
		},
		{
			title: "清机总金额(元)",
			dataIndex: "clear_time",
		},
		{
			title: "清机1元(枚)",
			dataIndex: "c100",
		},
		{
			title: "清机5角(枚)",
			dataIndex: "c50",
		},
		{
			title: "清机1角(枚)",
			dataIndex: "c10",
		},
		{
			title: "微信入账金额(元)",
			dataIndex: "wx_outamount",
		},
		{
			title: "支付宝入账金额(元)",
			dataIndex: "ali_outamount",
		},
		{
			title: "出入账误差(元)",
			dataIndex: "amount",
		},
	];

	export default {
		data() {
			return {
				expand: false,
				columns,
				searchData: {
					page: 1,
					number: '',
					bank_id: undefined,
					node_id: undefined,
					status: undefined,
					start_date: '',
					end_date: ''
				},
				list: [],
				total_page: 0,

				// 表格选择的数据, 存储的是id
				selectedRowKeys: [],
				// 存储的是数据
				selectedRowDataList: [],
				// 当前是第几条编辑信息
				editIndex: 0,

				detailLoading: false,

				detailList: [],
				total_detail_page: 0,


				bankTreeData: [],
				outletsList: [],
				
				pickerKey: Math.random()
			};
		},
		mounted() {
			this.getList()
		},
		created() {
			this.getOutletsList()
			this.getBankTreeData()
		},
		methods: {
			moment,
			disabledDate(current) {
			     return current && current >= moment().subtract("days"); //当天之前的不可选，不包括当天
			   //return current && current < moment().endOf(‘day’);当天之前的不可选，包括当天
			},
			handleReset() {
				this.pickerKey = Math.random()
				this.searchData = {
					page: 1,
					number: '',
					bank_id: undefined,
					node_id: undefined,
					status: undefined,
					start_date: '',
					end_date: ''
				}
				this.getList();
			},
			// 日期解析
			handleChangeDate(date) {
				this.searchData.start_date = this.moment(date[0]).format('YYYY-MM-DD')
				this.searchData.end_date = this.moment(date[1]).format('YYYY-MM-DD')
			},
			// 表格分页
			handleTableChange(pagination, filters, sorter) {
				this.searchData.page = pagination.current
				this.getList();
			},
			// 表格选择
			onSelectChange(selectedRowKeys) {
				this.selectedRowKeys = selectedRowKeys;
			},
			// 列表
			getList() {
				http({
					method: 'post',
					url: '/api.transaction.list/',
					data: {
						...this.searchData,
						bank_id: this.searchData.bank_id || 0,
						node_id: this.searchData.node_id || 0,
						status: this.searchData.status || 0
					}
				}).then((res) => {
					this.list = res.data.list
					this.total_page = res.data.total_page
				})
			},
			// 获取网点
			getOutletsList() {
				http({
					method: 'post',
					url: '/api.bank.node.treelist/',
					data: {bank_id: 0}
				}).then((res) => {
					this.outletsList = res.data
				})
			},
			// 获取银行类型
			getBankTreeData() {
				http({
					method: 'post',
					url: '/api.bank.list/',
					data: {
						page: 1,
						parent_id: 0
					}
				}).then((res) => {
					this.bankTreeData = this.dataDealwith(res.data)
				})
			},
			dataDealwith(treeData) {
				function setGrayNode(data) { //遍历树  获取id数组
					for (var i = 0; i < data.length; i++) {
						data[i] = {
							value: data[i].bank_id,
							key: data[i].bank_id,
							title: data[i].name,
							children: data[i].list || []
						} //子节点传给另一个函数专门用来改数据
						if (data[i].children && data[i].children.length) { // 如果当前节点有子节点，就递归调用一次
							setGrayNode(data[i].children);
						}
					}
				}
				setGrayNode(treeData)
				return treeData;
			},
		},
	};
</script>
<style>
	.ant-advanced-search-form {
		padding: 24px;
		background: #fbfbfb;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
	}

	.ant-advanced-search-form .ant-form-item {
		display: flex;
	}

	.ant-advanced-search-form .ant-form-item-control-wrapper {
		flex: 1;
	}

	.search-list-box .ant-form {
		max-width: none;
	}

	.search-list-box .search-result-list {
		margin-top: 16px;
		border: 1px dashed #e9e9e9;
		border-radius: 6px;
		background-color: #fafafa;
		min-height: 200px;
		text-align: center;
		padding-top: 80px;
	}

	.imgs {
		display: flex;
	}

	.imgs img {
		width: 140px;
		height: 200px;
	}
</style>
